<script>
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";
import { Autoplay } from "swiper";

export default {
  name: "service-page",
  data() {
    return {
      service: {},
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
  methods: {
    fetch_service_data() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/v1/dashboard/fetch_service_details", {service_id: this.$route.params.id}).then(({data}) => {
        this.service = data.data;
      });
    },
  },
  mounted() {
    this.fetch_service_data()
  }
}
</script>

<template>
  <section class="service_page">
    <div class="container">
      <div class="page-title">
        <div class="row">
          <div
              class="col-lg-8 col-md-8 col-sm-12 hidden-xs-down hidden-sm-down"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'Home' }">{{
                    $t("home")
                  }}</router-link>
              </li>
              <li class="breadcrumb-item active">
                <router-link to="#">{{ $t("service_details") }}</router-link>
              </li>
            </ol>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <div class="upper_content">
        <swiper
            :loop="true"
            :autoplay="{
              delay: 2500,
              disableOnInteraction: false,
            }"
            :breakpoints="{
              '320': {
                slidesPerView: 1,
                spaceBetween: 20,
              },
            }"
            :modules="modules"
            class="mySwiper"
        >
          <swiper-slide
              v-for="(image, index) in service.images"
              :key="index"
          >
            <div class="img">
              <img :src="image" alt="project" />
            </div>
          </swiper-slide>
        </swiper>
        <h1>{{ service.title }}</h1>
        <p v-html="service.text"></p>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
  .service_page {
    margin-top: 7.5rem;
    .page-title {
        padding: 3% 1%;
        background: rgba($color: #1B2E4E, $alpha: 0.03);
        margin-bottom: 3%;
        border-radius: 10px;
        .breadcrumb {
          float: none;
          text-align: left;
          padding: 0;
          color: var(--bg5);
          margin: 0;
          .breadcrumb-item {
            float: none;
            font-size: 12px;
            display: inline-block;
            a {
              font-family: "regular",serif;
              color: var(--color-primary);
              font-size: 1rem;
            }
          }
          .breadcrumb-item.active {
            a {
              font-family: "bold",serif;
            }
          }
        }
      }
    .upper_content {
      padding: 3% 0;
      .img {
        height: 500px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      h1 {
        font-family: "semibold",serif;
        color: var(--color-primary);
        font-size: 2rem;
        padding-inline-end: 20%;
        margin: 3% 0;
      }
      p {
        color: var(--color-fourth);
        font-family: "regular",serif;
        font-size: 1rem;
        padding-inline-end: 15%;
      }
    }
  }
</style>