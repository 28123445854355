<template>
  <section>
    <PageLoader />
    <button-top />
    <Navbar />
    <router-view />
    <Footer />
  </section>
</template>

<script>
// import AOS from "aos";

import buttonTop from "./view/components/static/buttonTop.vue";
import Navbar from "./view/components/static/Navbar.vue";
import Footer from "./view/components/static/Footer.vue";
import PageLoader from "./view/components/static/pageLoader.vue";

require("./assets/scss/style.min.css");
require('vue-it-bigger/dist/vue-it-bigger.min.css')

export default {
  name: "app",
  components: {
    buttonTop,
    Navbar,
    Footer,
    PageLoader
  },
  // mounted() {
  //   AOS.init();
  // }
};
</script>

<style lang="scss">
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.0/font/bootstrap-icons.css");

:root {
  --color-primary: #1B2E4E;
  --color-secound: #1B2E4E;
  --color-third: #4e4e4e;
  --color-fourth: #747474;
  --color-fifth: #a8a8a8;
  --color-sixth: #cad6d0;
  --color-seven: #efede1;
  --color-white: #ffffff;
}
@font-face {
  font-family: "light";
  src: url("./assets/fonts/Cairo-Light.ttf");
}
@font-face {
  font-family: "regular";
  src: url("./assets/fonts/Cairo-Regular.ttf");
}

@font-face {
  font-family: "semibold";
  src: url("./assets/fonts/Cairo-SemiBold.ttf");
}
@font-face {
  font-family: "meduim";
  src: url("./assets/fonts/Cairo-Medium.ttf");
}
@font-face {
  font-family: "bold";
  src: url("./assets/fonts/Cairo-Bold.ttf");
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  list-style: none;
  direction: ltr;
  height: 100vh;
  overflow-y: auto !important;
}
html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none !important;
  color: var(--color-white);
}

ul {
  list-style: none;
  padding: 0;
}
p {
  margin-bottom: 0;
}
/* scroll bar */
*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-track {
  background: var(--color-primary);
}
*::-webkit-scrollbar-thumb {
  background: var(--color-secound);
}
*::-webkit-scrollbar-thumb:hover {
  background: var(--color-fourth);
}

/* scroll bar */
//custom var
.text {
  font-size: 1.2rem;
  color: #fff;
  font-family: "bold",serif;
}
.parg {
  font-size: 0.8rem;
  color: var(--color-sixth);
  font-family: "regular",serif;
  text-align: center;
  line-height: 1.7;
}

.main-title {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h2 {
    font-size: 2.2rem;
    font-family: "bold",serif;
    color: var(--color-text);
  }
  p {
    font-size: 1.1rem;
    font-family: "regular",serif;
    color: var(--color-pargraph);
    padding: 2% 19%;
    text-align: center;
  }
}

.header_footer {
  font-size: 1.2rem;
  color: var(--color-white);
  font-family: "meduim",serif;
  position: relative;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 2px;
    background-color: var(--color-primary);
    color: var(--color-primary);
  }
}

//form content
.input-group {
  position: relative;
  margin-bottom: 3%;
  .icon_form {
    position: absolute;
    right: 0.3rem;
    top: 58%;
  }
  .form-control {
    border-radius: 2px;
    padding-right: 6%;
    background-color: transparent;
    color: var(--color-primary);
    font-family: "regular",serif;
    &:focus {
      box-shadow: none !important;
      border-color: var(--color-white);
      border-bottom: 1px solid var(--color-secound);
    }
    &::placeholder {
      color: var(--color-secound) !important;
      font-family: "regular",serif !important;
      font-size: 0.9rem;
    }
  }
  textarea {
    height: 200px;
    width: 100%;
    border: 1px solid #ced4da;
  }
  textarea.form-control {
    padding-right: 2%;
    border: 1px solid #ced4da;
  }
}
.form-label {
  width: 100%;
  font-size: 1rem;
  color: var(--color-text);
  font-family: "regular",serif;
}

.form-select {
  border-radius: 0;
  padding: 0.375rem 2.25rem 0.375rem 1.4rem;
  font-family: "regular",serif;
  background-color: var(--color-white);
  color: var(--color-text);
  background-position: left;
  position: relative;
  &:focus {
    box-shadow: unset !important;
  }
}

.btn:focus,
.form-check-input:focus,
.form-check :focus,
.btn-check:focus + .btn,
.btn-close:focus,
.accordion-button:focus {
  box-shadow: none;
}

input[type="data"] {
  color: var(--color-secound);
  font-family: "regular",serif;

  &::placeholder {
    content: "";
  }
}

.form-check {
  .form-check-input {
    float: right;
    margin-left: 5px;
    &:checked {
      background-color: var(--color-primary);
      border-color: var(--color-primary);
    }
  }
  .form-check-label {
    font-size: 1rem;
    font-family: "regular",serif;
    color: var(--color-text);
  }
}

::-webkit-calendar-picker-indicator {
  background-color: #ffffff;
  opacity: 0;
  padding: 5px;
  cursor: pointer;
  width: 50%;
  border-radius: 3px;
}

/* delete arrow from input number */

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.main-title {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 4%;
  h6 {
    color: var(--color-primary);
    font-size: 0.9rem;
    font-family: "regular",serif;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 25px;
      height: 2px;
      color: var(--color-primary);
      background: var(--color-primary);
      bottom: -4px;
      left: 0;
    }
  }
  h2 {
    font-family: "bold",serif;
    color: var(--color-primary);
    font-size: 1.9rem;
    padding: 1% 0;
  }
  p {
    font-family: "regular",serif;
    color: var(--color-fourth);
    font-size: 1.2rem;
    text-align: start;
    padding: 1% 0;
  }
}

.main-title-center {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;
  h6 {
    color: var(--color-primary);
    font-size: 0.9rem;
    font-family: "regular",serif;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 15px;
      height: 2px;
      background: var(--color-primary);
      color: var(--color-primary);
      bottom: -4px;
      left: 0;
    }
  }
  h2 {
    font-family: "bold",serif;
    color: var(--color-primary);
    font-size: 1.9rem;
    padding: 1% 0;
  }
  p {
    font-family: "regular",serif;
    color: var(--color-fourth);
    font-size: 1.4rem;
    text-align: center;
    padding: 0 12%;
  }
}
.button {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
  margin-top: 8%;
  .secondary {
    background-color: var(--color-primary);
    font-family: "semibold",serif;
    color: var(--color-white);
    font-size: 1rem;
    padding: 3% 2%;
    width: 50%;
    border: 1px solid var(--color-sixth);
    border-radius: 0;
    transition: 0.3s all ease-in-out;
    text-decoration: none;
    text-align: center;
    &:hover {
      background-color: var(--color-white);
      color: var(--color-primary);
      border: 1px solid var(--color-primary);
      border-radius: 30px;
      border-top-left-radius: 0;
    }
  }
  #preloader {
  background-image: url(../src/assets/image/loader.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  background-size: 15%;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 1100;
}

  .primary {
    background-color: var(--color-white);
    font-family: "semibold",serif;
    color: var(--color-primary);
    font-size: 1.3rem;
    padding: 3% 2%;
    width: 40%;
    border: 1px solid var(--color-sixth);
    border-radius: 0;
    transition: 0.3s all ease-in-out;
    text-decoration: none;
    text-align: center;
    &:hover {
      background-color: var(--color-white);
      color: var(--color-primary);
      border-radius: 30px;
      border-top-left-radius: 0;
    }
  }
}
.sr-only {
  display: none !important;
}
.page-item {
  margin-inline-end: 2% !important;
  .page-link {
    color: var(--color-secound) !important;
    border-color: var(--color-secound) !important;
  }
}
.page-item.active {
  .page-link {
    background-color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
    color: var(--color-white) !important;
  }
}
.service_section {
  .swiper-pagination {
    .swiper-pagination-bullet {
      width: 17px !important;
      height: 3px !important;
      border-radius: 3px !important;
    }
    .swiper-pagination-bullet-active {
      background-color: var(--color-primary);
      border: 1px solid var(--color-primary);
    }
  }
}
.team_section {
  .swiper {
    .swiper-button-prev,
    .swiper-button-next{
      top: 40% !important;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: rgba($color: #000000, $alpha: 0.3);
      display: flex;
      justify-content: center;
      align-content: center;
      text-align: center;
      transition: .25s all ease-in-out;
      &::after{
        opacity: 0;
        display: none;
      }
      &:hover{
        background: var(--color-white);
        i{
          color: var(--color-secound);
        }
      }
      i{
        color: var(--color-white);
        font-size: 2rem;
        transition: .25s all ease-in-out;

      }
     
    }
    .swiper-pagination {
      .swiper-pagination-bullet {
        width: 17px !important;
        height: 3px !important;
        border-radius: 3px !important;
      }
      .swiper-pagination-bullet-active {
        background-color: var(--color-primary);
        border: 1px solid var(--color-primary);
      }
    }
  }
}
@media (max-width: 991.98px) {
  header {
    .mobile-menu {
      top: 2% !important;
      i {
        padding: 0.1rem 0.3rem !important;
      }
    }
  }
}
@media (max-width: 375.98px) {
  .button {
    .secondary {
      width: 60%;
    }
  }
}
</style>
